export default ({ app }, inject) => {
  ;(function (d, w, c) {
    if (!d.getElementById('spd-busns-spt')) {
      const n = d.getElementsByTagName('script')[0]
      const s = d.createElement('script')
      let loaded = false
      s.id = 'spd-busns-spt'
      s.async = 'async'
      s.setAttribute('data-self-init', 'false')
      s.setAttribute('data-init-type', 'opt')
      s.src = 'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js'
      s.setAttribute('data-client', '091f76d592f4adf29c92dddbf2dd8839f125c98e')
      s.setAttribute(
        'data-bot-hash',
        'e2508ee31b7aa09f8a0d86a76e722d274d4f72c6'
      )
      s.setAttribute('data-env', 'prod')
      s.setAttribute('data-region', 'euc')
      if (c) {
        s.onreadystatechange = s.onload = function () {
          if (!loaded) {
            c()
          }
          loaded = true
        }
      }
      n.parentNode.insertBefore(s, n)
    }
  })(document, window, function () {
    Freshbots.initiateWidget(
      {
        autoInitChat: false,
        getClientParams: function () {
          return
        },
      },
      function (successResponse) {},
      function (errorResponse) {}
    )
  })
}
