import fetchPonyfill from 'fetch-ponyfill'
import { createApollo } from '@Apollo/core'

const moduleDefaults = {
  graphqlEndpoint: "",
  storeNamespace: "customer",
  ANONYMOUS_USER: "",
  notification: {
    NETWORK_ERROR: "Our ticketing server is not available, please try again shortly.",
    GQL_ERROR: {
      generic: "Loading data failed.",
      getEvents: "Could not retrieve events from the ticketing server.",
      getBasket: "Failed to load basket.",
      getActiveCustomer: "Failed to load customer data.",
      login: "We could not log you in to our ticketing system, please check your details and try again",
      addTicket: "That ticket could not be added to your basket. Please make another selection and try again",
      getCustomerTickets: "Your tickets could not be loaded, please try again.",
      "{This seat combination would leave single seats}": "Sorry, we require that your selection doesn't leave a single seat at the end of a row please check your selected seats and remove any that leave a single seat.",
      "Token is invalid": "Sorry, we were unable to log you in using this password reset token. It may have expired. Please try again",
      "Multiple membership contributions to the same Membership Organization are not allowed.": "You can not add more than one of the same membership type to your basket",
      "Invalid Source_no": "Sorry, that does not appear to be a valid source ID",
      "Invalid Gift Certificate number": "Sorry, that does not appear to be a valid gift/credit voucher",
      addGiftCertificate: "Your gift certificate could not be added to your basket. Please try again.",
      updatePassword: "There was a problem, your password could not be updated. Please try again."
    }
  },
  defaultClient: "persistedClient"
};

export default (ctx, inject) => {
  const options = {
  "modules": {
    "local": [
      "@skyway/apollo",
      "@skyway/core",
      "@skyway/gtm",
      "@skyway/ui",
      "@skyway/auth",
      "@skyway/account",
      "@skyway/contributions",
      "@skyway/custom-messages",
      "@skyway/events",
      "@skyway/classes",
      "@skyway/room-booking",
      "@skyway/packages",
      "@skyway/basket",
      "@skyway/tickets",
      "@skyway/gifts",
      "@skyway/forms",
      "@skyway/marketing"
    ]
  },
  "required": [
    "@skyway/apollo",
    "@skyway/core",
    "@skyway/ui"
  ],
  "modulesDir": "/home/circleci/project/modules",
  "vendorDir": "/home/circleci/project/vendor",
  "nodeModulesDir": "node_modules",
  "vuex": true,
  "i18n": [],
  "isDev": false,
  "logLoadedModules": true,
  "verbose": true,
  "directories": {
    "assets": "assets",
    "components": "components",
    "config": "config",
    "layouts": "layouts",
    "locales": "locales",
    "middleware": "middleware",
    "pages": "pages",
    "plugins": "plugins",
    "services": "services",
    "store": "store"
  },
  "apollo": {
    "graphqlEndpoint": "https://skyway.roundhouse.org.uk/graphql",
    "ANONYMOUS_USER": "anonymous@roundhouse.org.uk",
    "notification": {
      "GQL_ERROR": {
        "Multiple membership contributions to the same Membership Organization are not allowed.": "It looks like you already have a membership of this type. If you would like to renew, please go back and select renewal."
      }
    }
  },
  "allModules": [
    {
      "name": "@skyway/apollo",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/apollo/src"
    },
    {
      "name": "@skyway/core",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/core/src"
    },
    {
      "name": "@skyway/gtm",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/gtm/src"
    },
    {
      "name": "@skyway/ui",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/ui/src"
    },
    {
      "name": "@skyway/auth",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/auth/src"
    },
    {
      "name": "@skyway/account",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/account/src"
    },
    {
      "name": "@skyway/contributions",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/contributions/src"
    },
    {
      "name": "@skyway/custom-messages",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/custom-messages/src"
    },
    {
      "name": "@skyway/events",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/events/src"
    },
    {
      "name": "@skyway/classes",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/classes/src"
    },
    {
      "name": "@skyway/room-booking",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/room-booking/src"
    },
    {
      "name": "@skyway/packages",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/packages/src"
    },
    {
      "name": "@skyway/basket",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/basket/src"
    },
    {
      "name": "@skyway/tickets",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/tickets/src"
    },
    {
      "name": "@skyway/gifts",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/gifts/src"
    },
    {
      "name": "@skyway/forms",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/forms/src"
    },
    {
      "name": "@skyway/marketing",
      "type": "local",
      "path": "/home/circleci/project/modules/@skyway/marketing/src"
    }
  ]
}
  const apollo = createApollo(ctx, Object.assign(moduleDefaults, options.apollo))

  /**
   * Inject our default apollo client to make it available across the application
   */
  inject('apollo', apollo.provider.defaultClient)
  inject('apolloNonPersisted', apollo.provider.clients.nonPersisted)
}
