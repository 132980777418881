import { gtmProductsTransformer } from '@Basket/helpers/gtmProductsTransformer'
import { sumBy } from 'lodash'

export default ({ app, route }) => {
  /**
   * SOURCE ID
   */
  app.$eventBus.on('update_source', async (source) => {
    if (source !== undefined && source > 0) {
      const success = await app.store.dispatch(
        'basket/setSource',
        parseInt(source)
      )
      if (success) {
        await app.store.dispatch('basket/getBasketProperties')
      }
    }
  })

  /**
   * PROMO CODE
   */
  // app.$eventBus.on('update_promo_code', async (promo) => {
  //   if (promo !== undefined) {
  //     await app.store.dispatch('basket/applyPromoCode', promo).then((res) => {
  //       app.$eventBus.emit('promo_code_applied', promo)
  //     })

  //     await app.store.dispatch('basket/transferBasket')

  //   }
  // })

  /**
   * MODE OF SALE
   */
  app.$eventBus.on('update_mode_of_sale', (mos) => {
    if (mos !== undefined && mos > 0) {
      app.store.dispatch('basket/setModeOfSale', parseInt(mos))
    }
  })

  app.$eventBus.on('revert_mode_of_sale', () => {
    app.store.dispatch('basket/revertModeOfSale')
  })

  /**
   * GIFT AID
   * set up a listener so that gift aid requests will be processed on login
   */
  app.$eventBus.on('login', () => {
    app.store.dispatch('customer/processGiftAidRequests')
  })

  /**
   * COOKIES
   */
  app.$eventBus.on('update_cookie', (params) => {
    let cookie = {}
    if (app.$cookies.get('bridge_user_status')) {
      cookie = app.$cookies.get('bridge_user_status')
    }

    const cookie_contents = Object.assign(cookie, params)

    app.$cookies.set('bridge_user_status', JSON.stringify(cookie_contents))
  })

  app.$eventBus.on('clear_cookie', (params) => {
    if (app.$cookies.get('bridge_user_status')) {
      app.$cookies.remove('bridge_user_status')
    }
  })

  // if item is added to an empty basket then we should update the expiry time as if this was a new basket
  app.$eventBus.on('basket:item-added', () => {
    let cookie = {}
    if (app.$cookies.get('bridge_user_status')) {
      cookie = app.$cookies.get('bridge_user_status')
    }

    if (cookie.basket_count === 1) {
      console.log(app.store?.state?.basket?.expiry)
      // app.store.dispatch(
      //   'basket/updateBasketExpiry',
      //   app.$moment(app.store.basket.expiry).utc().add(20, 'minutes').toDate()
      // )
    }
  })

  /**
   * GOOGLE ANALYTICS
   */
  app.$eventBus.on('gtm:add-to-basket', (product) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'addToBasket',
        ecommerce: {
          currencyCode: 'GBP',
          add: {
            products: [product],
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:remove-from-basket', (product) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'removeFromBasket',
        ecommerce: {
          remove: {
            products: [product],
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:select-delivery-method', ({ step, option }) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'checkoutOption',
        ecommerce: {
          currencyCode: 'GBP',
          checkout_option: {
            actionField: {
              step,
              option,
            },
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:proceed-to-delivery', (products) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: gtmProductsTransformer(products),
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:proceed-to-payment', (params) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: params.deliveryMethodName,
            },
            products: gtmProductsTransformer(params.products),
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:complete-transaction', ({ order, promo }) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.order_ref,
              affiliation: app.$config.get('CLIENT_NAME'),
              revenue: order.total,
              tax: 0,
              shipping: order.order_fees.length
                ? sumBy(order.order_fees, (fee) => fee.amount)
                : 0,
              coupon: promo,
            },
            products: gtmProductsTransformer(order.groups),
          },
        },
      })
    }
  })

  if (route.query.source) {
    app.$eventBus.emit('update_source', route.query.source)
  }

  // if (route.query.promo && !app.store.state.basket.promo_code) {
  //   app.$eventBus.emit('update_promo_code', route.query.promo)
  // }
}
