import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_86717aca from 'nuxt_plugin_sentryserver_86717aca' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_570dd113 from 'nuxt_plugin_sentryclient_570dd113' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_resizeobserverpolyfill_1ea89084 from 'nuxt_plugin_resizeobserverpolyfill_1ea89084' // Source: ./nuxt-polyfill/resize-observer-polyfill.js (mode: 'all')
import nuxt_plugin_vuescrollto_3d13675c from 'nuxt_plugin_vuescrollto_3d13675c' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_recaptcha_f0e9d674 from 'nuxt_plugin_recaptcha_f0e9d674' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_nuxtmq_63af311f from 'nuxt_plugin_nuxtmq_63af311f' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_60265b7f from 'nuxt_plugin_cookieuniversalnuxt_60265b7f' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_a39da578 from 'nuxt_plugin_pluginutils_a39da578' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_c6c90ce2 from 'nuxt_plugin_pluginrouting_c6c90ce2' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_8c411780 from 'nuxt_plugin_pluginmain_8c411780' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_portalvue_93d59e48 from 'nuxt_plugin_portalvue_93d59e48' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_moment_0f1f82a1 from 'nuxt_plugin_moment_0f1f82a1' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_router_967f0690 from 'nuxt_plugin_router_967f0690' // Source: ./router.js (mode: 'all')
import nuxt_plugin_pluginVuexmodules_47265cc3 from 'nuxt_plugin_pluginVuexmodules_47265cc3' // Source: ./pluginVuex.modules.js (mode: 'all')
import nuxt_plugin_pluginmodules_d893d956 from 'nuxt_plugin_pluginmodules_d893d956' // Source: ./plugin.modules.js (mode: 'all')
import nuxt_plugin_srcgtm0725ae1c_62ae9949 from 'nuxt_plugin_srcgtm0725ae1c_62ae9949' // Source: ./src.gtm.0725ae1c.js (mode: 'client')
import nuxt_plugin_pluginsregisterClient084b3f10_0fc5cf53 from 'nuxt_plugin_pluginsregisterClient084b3f10_0fc5cf53' // Source: ./plugins.registerClient.084b3f10.js (mode: 'all')
import nuxt_plugin_globalcomponents_855745ca from 'nuxt_plugin_globalcomponents_855745ca' // Source: ./modules/skywaymarketing/plugins/global-components.js (mode: 'all')
import nuxt_plugin_svgpanzoom_2a4dd070 from 'nuxt_plugin_svgpanzoom_2a4dd070' // Source: ./modules/skywaytickets/plugins/svg-pan-zoom.js (mode: 'client')
import nuxt_plugin_csi_c8f651ca from 'nuxt_plugin_csi_c8f651ca' // Source: ./modules/skywayaccount/plugins/csi.js (mode: 'all')
import nuxt_plugin_dialog_4972f6de from 'nuxt_plugin_dialog_4972f6de' // Source: ./modules/skywayui/plugins/dialog.js (mode: 'client')
import nuxt_plugin_hammer_69468e53 from 'nuxt_plugin_hammer_69468e53' // Source: ./modules/skywayui/plugins/hammer.js (mode: 'client')
import nuxt_plugin_isMobileOrTablet_1d5e80f8 from 'nuxt_plugin_isMobileOrTablet_1d5e80f8' // Source: ./modules/skywayui/plugins/isMobileOrTablet.js (mode: 'all')
import nuxt_plugin_isMobile_df10e726 from 'nuxt_plugin_isMobile_df10e726' // Source: ./modules/skywayui/plugins/isMobile.js (mode: 'all')
import nuxt_plugin_globalcomponents_397ef756 from 'nuxt_plugin_globalcomponents_397ef756' // Source: ./modules/skywayui/plugins/global-components.js (mode: 'all')
import nuxt_plugin_freshdeskWidget_cd79a6c2 from 'nuxt_plugin_freshdeskWidget_cd79a6c2' // Source: ./modules/skywaycore/plugins/freshdeskWidget.js (mode: 'client')
import nuxt_plugin_breakpoint_647ae413 from 'nuxt_plugin_breakpoint_647ae413' // Source: ./modules/skywaycore/plugins/breakpoint.js (mode: 'client')
import nuxt_plugin_cypress_f1f62e86 from 'nuxt_plugin_cypress_f1f62e86' // Source: ./modules/skywaycore/plugins/cypress.js (mode: 'client')
import nuxt_plugin_vuexPersist_19c542e8 from 'nuxt_plugin_vuexPersist_19c542e8' // Source: ./modules/skywaycore/plugins/vuexPersist.js (mode: 'client')
import nuxt_plugin_vueAuthenticate_04d11d0f from 'nuxt_plugin_vueAuthenticate_04d11d0f' // Source: ./modules/skywaycore/plugins/vueAuthenticate.js (mode: 'client')
import nuxt_plugin_logger_9e958ed8 from 'nuxt_plugin_logger_9e958ed8' // Source: ./modules/skywaycore/plugins/logger.js (mode: 'all')
import nuxt_plugin_currency_b09a699a from 'nuxt_plugin_currency_b09a699a' // Source: ./modules/skywaycore/plugins/currency.js (mode: 'all')
import nuxt_plugin_registerServices_4c6e7423 from 'nuxt_plugin_registerServices_4c6e7423' // Source: ./modules/skywaycore/plugins/registerServices.js (mode: 'all')
import nuxt_plugin_globalComponents_23c263aa from 'nuxt_plugin_globalComponents_23c263aa' // Source: ./modules/skywaycore/plugins/globalComponents.js (mode: 'all')
import nuxt_plugin_uniqueId_110c5898 from 'nuxt_plugin_uniqueId_110c5898' // Source: ./modules/skywaycore/plugins/uniqueId.js (mode: 'all')
import nuxt_plugin_config_29d114bc from 'nuxt_plugin_config_29d114bc' // Source: ./modules/skywaycore/plugins/config.js (mode: 'all')
import nuxt_plugin_announcer_1c265ae1 from 'nuxt_plugin_announcer_1c265ae1' // Source: ./modules/skywaycore/plugins/announcer.js (mode: 'all')
import nuxt_plugin_collect_42e51000 from 'nuxt_plugin_collect_42e51000' // Source: ./modules/skywaycore/plugins/collect.js (mode: 'all')
import nuxt_plugin_globalEventBus_29203f8a from 'nuxt_plugin_globalEventBus_29203f8a' // Source: ./modules/skywaycore/plugins/globalEventBus.js (mode: 'all')
import nuxt_plugin_crowdhandler_bddfd972 from 'nuxt_plugin_crowdhandler_bddfd972' // Source: ./modules/skywaycore/plugins/crowdhandler.js (mode: 'client')
import nuxt_plugin_canonical_87ebd194 from 'nuxt_plugin_canonical_87ebd194' // Source: ./modules/skywaycore/plugins/canonical.js (mode: 'all')
import nuxt_plugin_globalListeners_36e669e4 from 'nuxt_plugin_globalListeners_36e669e4' // Source: ./modules/skywaycore/plugins/globalListeners.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Roundhouse","htmlAttrs":{"lang":"en"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"hid":"description","name":"description","content":"Roundhouse Website"},{"hid":"robots","name":"robots","content":"index, follow"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"stylesheet","href":"https:\u002F\u002Fuse.typekit.net\u002Foqw1njk.css"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fimages\u002Ffavicons\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fimages\u002Ffavicons\u002Ffavicon.ico"},{"rel":"shortcut icon","type":"image\u002Fpng","href":"\u002Fimages\u002Ffavicons\u002Ffavicon.ico"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Fimages\u002Ffavicons\u002Ffavicon-16x16.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Fimages\u002Ffavicons\u002Ffavicon-32x32.png"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_86717aca === 'function') {
    await nuxt_plugin_sentryserver_86717aca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_570dd113 === 'function') {
    await nuxt_plugin_sentryclient_570dd113(app.context, inject)
  }

  if (typeof nuxt_plugin_resizeobserverpolyfill_1ea89084 === 'function') {
    await nuxt_plugin_resizeobserverpolyfill_1ea89084(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_3d13675c === 'function') {
    await nuxt_plugin_vuescrollto_3d13675c(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_f0e9d674 === 'function') {
    await nuxt_plugin_recaptcha_f0e9d674(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_63af311f === 'function') {
    await nuxt_plugin_nuxtmq_63af311f(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_60265b7f === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_60265b7f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_a39da578 === 'function') {
    await nuxt_plugin_pluginutils_a39da578(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_c6c90ce2 === 'function') {
    await nuxt_plugin_pluginrouting_c6c90ce2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_8c411780 === 'function') {
    await nuxt_plugin_pluginmain_8c411780(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_93d59e48 === 'function') {
    await nuxt_plugin_portalvue_93d59e48(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_0f1f82a1 === 'function') {
    await nuxt_plugin_moment_0f1f82a1(app.context, inject)
  }

  if (typeof nuxt_plugin_router_967f0690 === 'function') {
    await nuxt_plugin_router_967f0690(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginVuexmodules_47265cc3 === 'function') {
    await nuxt_plugin_pluginVuexmodules_47265cc3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmodules_d893d956 === 'function') {
    await nuxt_plugin_pluginmodules_d893d956(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_srcgtm0725ae1c_62ae9949 === 'function') {
    await nuxt_plugin_srcgtm0725ae1c_62ae9949(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsregisterClient084b3f10_0fc5cf53 === 'function') {
    await nuxt_plugin_pluginsregisterClient084b3f10_0fc5cf53(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_855745ca === 'function') {
    await nuxt_plugin_globalcomponents_855745ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_svgpanzoom_2a4dd070 === 'function') {
    await nuxt_plugin_svgpanzoom_2a4dd070(app.context, inject)
  }

  if (typeof nuxt_plugin_csi_c8f651ca === 'function') {
    await nuxt_plugin_csi_c8f651ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dialog_4972f6de === 'function') {
    await nuxt_plugin_dialog_4972f6de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hammer_69468e53 === 'function') {
    await nuxt_plugin_hammer_69468e53(app.context, inject)
  }

  if (typeof nuxt_plugin_isMobileOrTablet_1d5e80f8 === 'function') {
    await nuxt_plugin_isMobileOrTablet_1d5e80f8(app.context, inject)
  }

  if (typeof nuxt_plugin_isMobile_df10e726 === 'function') {
    await nuxt_plugin_isMobile_df10e726(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_397ef756 === 'function') {
    await nuxt_plugin_globalcomponents_397ef756(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_freshdeskWidget_cd79a6c2 === 'function') {
    await nuxt_plugin_freshdeskWidget_cd79a6c2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_breakpoint_647ae413 === 'function') {
    await nuxt_plugin_breakpoint_647ae413(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cypress_f1f62e86 === 'function') {
    await nuxt_plugin_cypress_f1f62e86(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexPersist_19c542e8 === 'function') {
    await nuxt_plugin_vuexPersist_19c542e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueAuthenticate_04d11d0f === 'function') {
    await nuxt_plugin_vueAuthenticate_04d11d0f(app.context, inject)
  }

  if (typeof nuxt_plugin_logger_9e958ed8 === 'function') {
    await nuxt_plugin_logger_9e958ed8(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_b09a699a === 'function') {
    await nuxt_plugin_currency_b09a699a(app.context, inject)
  }

  if (typeof nuxt_plugin_registerServices_4c6e7423 === 'function') {
    await nuxt_plugin_registerServices_4c6e7423(app.context, inject)
  }

  if (typeof nuxt_plugin_globalComponents_23c263aa === 'function') {
    await nuxt_plugin_globalComponents_23c263aa(app.context, inject)
  }

  if (typeof nuxt_plugin_uniqueId_110c5898 === 'function') {
    await nuxt_plugin_uniqueId_110c5898(app.context, inject)
  }

  if (typeof nuxt_plugin_config_29d114bc === 'function') {
    await nuxt_plugin_config_29d114bc(app.context, inject)
  }

  if (typeof nuxt_plugin_announcer_1c265ae1 === 'function') {
    await nuxt_plugin_announcer_1c265ae1(app.context, inject)
  }

  if (typeof nuxt_plugin_collect_42e51000 === 'function') {
    await nuxt_plugin_collect_42e51000(app.context, inject)
  }

  if (typeof nuxt_plugin_globalEventBus_29203f8a === 'function') {
    await nuxt_plugin_globalEventBus_29203f8a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_crowdhandler_bddfd972 === 'function') {
    await nuxt_plugin_crowdhandler_bddfd972(app.context, inject)
  }

  if (typeof nuxt_plugin_canonical_87ebd194 === 'function') {
    await nuxt_plugin_canonical_87ebd194(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalListeners_36e669e4 === 'function') {
    await nuxt_plugin_globalListeners_36e669e4(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
