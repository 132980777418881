import { v4 as uuid4 } from 'uuid'
import Vue from 'vue'

/**
 * Vue recommend against using this._uid in components as it is not
 * a public property of the application and may change in the future
 */
export default () => {
  /**
   * Adds a mixin to every component
   */
  Vue.mixin({
    computed: {
      uniqueId() {
        return uuid4()
      },
    },
  })
}
