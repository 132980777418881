import Vue from 'vue'
/**
 * Injects a $isMobile function globally so we can check if on mobile devices
 */
export default (context, inject) => {
  /**
   * ! this.$mq is less than !
   * @param screenSize - this.$mq to get the screen size which cannot be accessed directly here
   * @returns Boolean - indicates whether on a mobile device
   */
  const isMobile = (screenSize) => {
    if (
      screenSize === 'screen375' ||
      screenSize === 'screen480' ||
      screenSize === 'screen640' ||
      screenSize === 'screen768'
    ) {
      return true
    } else {
      return false
    }
  }
  Vue.prototype.$isMobile = isMobile
  inject('isMobile', isMobile)
}
