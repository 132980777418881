import Vue from 'vue'
import { config } from '@Core/config/skyway'

class Config {
  constructor(config) {
    this.config = config
  }

  get(key) {
    if (config[key]) {
      return config[key]
    } else {
      return null
    }
  }
}

export default ({ app }, inject) => {
  inject('config', new Config(Object.assign(config, app.$config)))
}
