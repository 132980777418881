import AccountService from '@Account/services/account-service'
import AuthService from '@Auth/services/auth-service'
import { BasketService, SeatsService } from 'Services'
import CustomMessagesService from '@CustomMessages/services/custom-messages-service'
import DonationsService from '@Contributions/services/donations-service'
import EventsService from '@Events/services/events-service'
import FormsService from '@Forms/services/forms-service'
import GiftsService from '@Gifts/services/gifts-service'
import InstancesService from '@Events/services/instances-service'
import PackagesService from '@Packages/services/packages-service'
import MembershipsService from '@Contributions/services/memberships-service'
import WpSkywayService from '@Marketing/services/wp-skyway-service'

const createService = (Name, client1, client2) => {
  const service = new Name(client1, client2)

  return service
}

export default ({ app }, inject) => {
  const accountService = createService(AccountService, app.$apolloNonPersisted)
  const authService = createService(AuthService, app.$apolloNonPersisted)
  const basketService = createService(BasketService, app.$apolloNonPersisted)
  const giftsService = createService(GiftsService, app.$apolloNonPersisted)
  const seatsService = createService(SeatsService, app.$apolloNonPersisted)

  const customMessagesService = createService(
    CustomMessagesService,
    app.$apollo
  )
  const donationsService = createService(DonationsService, app.$apollo)
  const eventsService = createService(
    EventsService,
    app.$apollo,
    app.$apolloNonPersisted
  )
  const packagesService = createService(
    PackagesService,
    app.$apollo,
    app.$apolloNonPersisted
  )
  const formsService = createService(FormsService, app.$apollo)
  const instancesService = createService(
    InstancesService,
    app.$apollo,
    app.$apolloNonPersisted
  )
  const membershipsService = createService(MembershipsService, app.$apollo)
  const wpSkywayService = createService(WpSkywayService, app.$apollo)

  inject('account', accountService)
  inject('authService', authService)
  inject('basket', basketService)
  inject('customMessages', customMessagesService)
  inject('donations', donationsService)
  inject('instances', instancesService)
  inject('packages', packagesService)
  inject('events', eventsService)
  inject('forms', formsService)
  inject('gifts', giftsService)
  inject('memberships', membershipsService)
  inject('seats', seatsService)
  inject('wp', wpSkywayService)
}
