/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import extendsModules from '~/.nuxt/extends.modules';
import routerModules from '~/.nuxt/router.modules';

function middlewarePipeline({ context, middleware, index }) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) {
        return context.next;
    }

    return () => {
        const nextPipeline = middlewarePipeline({
            context, middleware, index: index + 1,
        });

        nextMiddleware({
            ...context,
            next: nextPipeline,
        });
    };
}

export function setLocalMiddlewares({
    to, from, next, ...context
}) {
    if (!to.meta.middleware) {
        return next();
    }
    const {
        middleware,
    } = to.meta;
    const ctx = {
        to,
        from,
        next,
        ...context,
    };

    return middleware[0]({
        ...ctx,
        next: middlewarePipeline({ context: ctx, middleware, index: 1 }),
    });
}

export function extendRoutes(routerLocal = []) {
    const extendRoutesChildren = Object.values(extendsModules)
        .reduce((acc, current) => {
            let connectedArray = acc;

            if (current.extendRoutesChildren) {
                connectedArray = [
                    ...acc,
                    ...current.extendRoutesChildren,
                ];
            }
            return connectedArray;
        }, []);
    const extendedRoutes = [].concat(...Object.values(routerModules), routerLocal);

    for (let i = 0; i < extendRoutesChildren.length; i += 1) {
        const index = extendedRoutes.findIndex(e => e.name === extendRoutesChildren[i].name);

        if (index !== -1) {
            const {
                children = [],
            } = extendedRoutes[index];

            extendedRoutes[index] = {
                ...extendedRoutes[index],
                children: [
                    ...children,
                    ...extendRoutesChildren[i].children,
                ],
            };
        }
    }

    return extendedRoutes;
}

export function scrollBehavior(to, from, savedPosition) {
    let position = false;

    if (to.matched.length < 2) {
        position = {
            x: 0,
            y: 0,
        };
    } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
        position = {
            x: 0,
            y: 0,
        };
    }
    if (savedPosition) {
        position = savedPosition;
    }

    return new Promise((resolve) => {
        window.$nuxt.$once('triggerScroll', () => {
            if (to.hash && document.querySelector(to.hash)) {
                position = {
                    selector: to.hash,
                };
            }
            resolve(position);
        });
    });
}
