/**
 * Add commonly used components to this file to prevent the need to import them
 * all over the place
 */

import Vue from 'vue'

import Accordion from '@UI/components/accordions/Accordion.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'
import ArrowLink from '@UI/components/links/ArrowLink.vue'
import AutoSuggestField from '@UI/components/forms/fields/multiselect/AutoSuggestField.vue'
import BlockTitle from '@UI/components/block-title/BlockTitle.vue'
import ButtonLink from '@UI/components/links/ButtonLink.vue'
import Modal from '@UI/components/modals/Modal'
import HeightTransition from '@UI/components/transitions/HeightTransition.vue'
import TransitionWrapper from '@UI/components/transitions/TransitionWrapper.vue'
import UIButton from '@UI/components/buttons/UIButton.vue'
import UIButtonLink from '@UI/components/buttons/UIButtonLink.vue'
import UICmsImage from '@UI/components/media/UICmsImage.vue'
import PhoneFormatField from '@UI/components/forms/fields/PhoneFormatField.vue'
import RadioButton from '@UI/components/forms/fields/RadioButton.vue'
import RadioGroup from '@UI/components/forms/fields/RadioGroup.vue'
import SelectField from '@UI/components/forms/fields/SelectField.vue'
import Loader from '@UI/components/loader/Loader.vue'
import TextField from '@UI/components/forms/fields/TextField.vue'
import LoqateField from '@UI/components/forms/fields/LoqateField.vue'
import PasswordFieldReveal from '@UI/components/forms/fields/PasswordFieldReveal.vue'
import ReadOnlyEditable from '@UI/components/forms/fields/ReadOnlyEditable.vue'
import SkipLink from '@UI/components/accessibility/SkipLink.vue'
import TextTag from '@UI/components/tags/TextTag.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import Observer from '@UI/components/intersection-observer/IntersectionObserver.vue'
import { validateUrl } from '@UI/helpers/validators'

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

extend('required', {
  message: 'This field is required',
})

extend('confirmed', {
  message: 'The password confirmation does not match',
})

extend('regex', {
  message: 'Please use alphabetic characters only',
})

extend('password', (value) => {
  if (
    value.match(/[0-9]/g) !== null && // at least 1 number
    value.match(/[a-zA-Z]/g) !== null && // characters from alphabet
    value.match(/.*[A-Z]/g) !== null && // at least 1 uppercase
    value.match(/.*[@#$%^*+!()"£{}[\];'?/\\=_|\-~¬`]/g) && // at least 1 special character
    !value.includes(':') &&
    !value.includes('<') &&
    !value.includes('>') &&
    !value.includes(' ') &&
    !value.includes('&') &&
    value.length > 7 // 8 characters long
  ) {
    return true
  }

  return 'Please note passwords cannot contain a space or the following invalid characters :, <, > or &.'
})

extend('email', {
  message: 'Please enter a valid email',
})

extend('url', (value) => {
  return validateUrl(value)
})

const components = {
  Modal,
  Accordion,
  AlertNotification,
  ArrowLink,
  AutoSuggestField,
  BlockTitle,
  ButtonLink,
  UIButton,
  UIButtonLink,
  UICmsImage,
  HeightTransition,
  TransitionWrapper,
  Loader,
  ValidationObserver,
  ValidationProvider,
  TextField,
  SelectField,
  LoqateField,
  PasswordFieldReveal,
  PhoneFormatField,
  ReadOnlyEditable,
  RadioGroup,
  RadioButton,
  Observer,
  SkipLink,
  TextTag,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
