/**
 * Add commonly used components to this file to prevent the need to import them
 * all over the place
 */

import Vue from 'vue'

import Error from '@Core/components/errors/Error.vue'

const components = {
  Error,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
