import Vue from 'vue'
import variables from '@UI/assets/scss/01-settings/_breakpoints.scss'
/**
 * Injects a $breakpoint function globally so we can check if on mobile devices
 */
export default (_, inject) => {
  /**
   * @returns String – returns breakpoint relevant to the current screen size
   */
  const calculateBreakpoint = () => {
    const breakpoints = Object.values(variables).filter((item) => {
      return item.includes('px')
    })

    const screensize =
      breakpoints &&
      breakpoints.reduce((acc, item) => {
        if (window.matchMedia(`(min-width: ${item})`).matches) {
          acc = item
        }

        return acc
      })

    return parseInt(screensize.replace('px', ''))
  }

  console.log('current breakpoint: ' + calculateBreakpoint() + 'px')

  const breakpoint = calculateBreakpoint()

  Vue.prototype.$breakpoint = breakpoint
  inject('breakpoint', breakpoint)
}
