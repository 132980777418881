export default function (to, from, savedPosition) {
  return new Promise(async (resolve, reject) => {
    // Maintain scroll position when adding url params (/whats-on)
    if (from.path === to.path) {
      resolve(savedPosition)
    }

    const html = document.documentElement

    const findEl = async (hash, x = 0) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve(document.querySelector('#app'))
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1))
          }, 100)
        })
      )
    }

    if (to.hash) {
      const el = await findEl(to.hash)
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
      } else {
        return window.scrollTo(0, el.offsetTop)
      }
    }

    html.classList.add('no-scroll')
    setTimeout(() => {
      resolve({ x: 0, y: 0 })
      html.classList.remove('no-scroll')
    }, 0)
  })
}
