/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import store_cf2f5ef4 from '/home/circleci/project/modules/@skyway/core/src/store/session/index.js';
import store_75489c7f from '/home/circleci/project/modules/@skyway/account/src/store/customerServiceIssues/index.js';
import store_f1cdcacc from '/home/circleci/project/modules/@skyway/account/src/store/customer/index.js';
import store_64f8d706 from '/home/circleci/project/modules/@skyway/contributions/src/store/memberships/index.js';
import store_321d2a8e from '/home/circleci/project/modules/@skyway/contributions/src/store/donations/index.js';
import store_11fddeb6 from '/home/circleci/project/modules/@skyway/custom-messages/src/store/customMessages/index.js';
import store_49fe16b8 from '/home/circleci/project/modules/@skyway/events/src/store/instances/index.js';
import store_ec6eaae6 from '/home/circleci/project/modules/@skyway/events/src/store/events/index.js';
import store_45689df1 from '/home/circleci/project/modules/@skyway/classes/src/store/classes/index.js';
import store_a870eec8 from '/home/circleci/project/modules/@skyway/room-booking/src/store/rooms/index.js';
import store_f68e1b66 from '/home/circleci/project/modules/@skyway/packages/src/store/packages/index.js';
import store_7ee76eed from '/home/circleci/project/modules/@skyway/basket/src/store/basket/index.js';
import store_43650928 from '/home/circleci/project/modules/@skyway/tickets/src/store/seats/index.js';
import store_5e8e8ff7 from '/home/circleci/project/modules/@skyway/gifts/src/store/gifts/index.js';
import store_0d9d5d1f from '/home/circleci/project/modules/@skyway/forms/src/store/forms/index.js';
import store_25978a42 from '/home/circleci/project/modules/@skyway/marketing/src/store/page/index.js';
import store_05089839 from '/home/circleci/project/modules/@skyway/marketing/src/store/options/index.js';
import store_60a5fd1c from '/home/circleci/project/modules/@skyway/marketing/src/store/menu/index.js';
import store_dd0132d6 from '/home/circleci/project/modules/@skyway/marketing/src/store/archive/index.js';

export default {
	session: store_cf2f5ef4,
	customerServiceIssues: store_75489c7f,
	customer: store_f1cdcacc,
	memberships: store_64f8d706,
	donations: store_321d2a8e,
	customMessages: store_11fddeb6,
	instances: store_49fe16b8,
	events: store_ec6eaae6,
	classes: store_45689df1,
	rooms: store_a870eec8,
	packages: store_f68e1b66,
	basket: store_7ee76eed,
	seats: store_43650928,
	gifts: store_5e8e8ff7,
	forms: store_0d9d5d1f,
	page: store_25978a42,
	options: store_05089839,
	menu: store_60a5fd1c,
	archive: store_dd0132d6,
};
