/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import route_a6912a5e from '/home/circleci/project/modules/@skyway/auth/src/config/routes.js';
import route_11d42ace from '/home/circleci/project/modules/@skyway/account/src/config/routes.js';
import route_5d0b4f98 from '/home/circleci/project/modules/@skyway/contributions/src/config/routes.js';
import route_0ab11c40 from '/home/circleci/project/modules/@skyway/events/src/config/routes.js';
import route_2bd1bb16 from '/home/circleci/project/modules/@skyway/classes/src/config/routes.js';
import route_22747572 from '/home/circleci/project/modules/@skyway/room-booking/src/config/routes.js';
import route_a7b13c9a from '/home/circleci/project/modules/@skyway/basket/src/config/routes.js';
import route_a1f05358 from '/home/circleci/project/modules/@skyway/tickets/src/config/routes.js';
import route_a0366490 from '/home/circleci/project/modules/@skyway/gifts/src/config/routes.js';
import route_e0366b68 from '/home/circleci/project/modules/@skyway/forms/src/config/routes.js';
import route_d8681796 from '/home/circleci/project/modules/@skyway/marketing/src/config/routes.js';

export default {
	route_a6912a5e,
	route_11d42ace,
	route_5d0b4f98,
	route_0ab11c40,
	route_2bd1bb16,
	route_22747572,
	route_a7b13c9a,
	route_a1f05358,
	route_a0366490,
	route_e0366b68,
	route_d8681796,
};
