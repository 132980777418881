import Vue from 'vue'
/**
 * Injects a $isMobileOrTablet function globally so we can check if on mobile or tablet devices
 */
export default (context, inject) => {
  /**
   * ! this.$mq is less than !
   * @param screenSize - this.$mq to get the screen size which cannot be accessed directly here
   * @returns Boolean - indicates whether on a mobile or tablet device
   */
  const isMobileOrTablet = (screenSize) => {
    if (
      screenSize === 'screen375' ||
      screenSize === 'screen480' ||
      screenSize === 'screen640' ||
      screenSize === 'screen768' ||
      screenSize === 'screen1024'
    ) {
      return true
    } else {
      return false
    }
  }
  Vue.prototype.$isMobileOrTablet = isMobileOrTablet
  inject('isMobileOrTablet', isMobileOrTablet)
}
