import Vue from 'vue'

import ColumnBlock from '@Marketing/components/content/column-block/ColumnBlock.vue'
import TextEditorBlock from '@Marketing/components/parts/wordpress/TextEditorBlock.vue'

const components = {
  ColumnBlock,
  TextEditorBlock,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
