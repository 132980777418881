import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

const GDPR_EXPIRY_TIME = 720 // 30 days in hours

export default ({ app, store }) => {
  new VuexPersistence({
    storage: window.localStorage, // or window.sessionStorage or localForage instance.
    // // Function that passes the state and returns the state with only the objects you want to store.
    reducer: (state) => ({
      classes: {
        selection: state.classes.selection,
        selectionExpiry: state.classes.selectionExpiry,
      },
      customer: {
        logged_in: state.customer.logged_in,
        token: state.customer.token,
        gift_aid_requests: state.customer.gift_aid_requests,
      },
      seats: {
        selection: state.seats.selection,
        chosen_screen_zone: state.seats.chosen_screen_zone,
      },
      gifts: {
        gift_vouchers: state.gifts.gift_vouchers,
        gift_memberships: state.gifts.gift_memberships,
      },
      customerServiceIssues: {
        csis: state.customerServiceIssues.csis,
      },
      basket: {
        gift_vouchers: state.basket.gift_vouchers,
        latest_order_id: state.basket.latest_order_id,
        recipients: state.basket.recipients,
        special_requests: state.basket.special_requests,
      },
      forms: {
        submissions: state.forms.submissions,
      },
      memberships: {
        csmSelection: state.memberships.selection,
        selectionExpiry: state.memberships.selectionExpiry,
      },
    }),
  }).plugin(store)

  const getExpiration = (hours) => {
    return new Date(new Date().getTime() + 60 * 60 * hours * 1000)
  }

  const vuexCookieBasket = new VuexPersistence({
    key: 'vuexBasket',
    restoreState: (key, storage) => {
      try {
        return JSON.parse(Cookies.get(key))
      } catch (err_) {}
    },
    saveState: (key, state, storage) =>
      Cookies.set(key, JSON.stringify(state), {
        expires: getExpiration(1),
      }),
    reducer: (state) => ({
      basket: {
        promo_code: state.basket.promo_code,
        key_info_form: state.basket.key_info_form,
        donation_dismissed: state.basket.donation_dismissed,
      },
      session: {
        redirect: state.session.redirect,
      },
    }),
  }).plugin(store)

  const vuexCookieSession = new VuexPersistence({
    key: 'vuexSession',
    restoreState: (key, storage) => {
      try {
        return JSON.parse(Cookies.get(key))
      } catch (err_) {}
    },
    saveState: (key, state, storage) =>
      Cookies.set(key, JSON.stringify(state), {
        expires: getExpiration(GDPR_EXPIRY_TIME),
      }),
    reducer: (state) => ({
      session: {
        cookiePreferences: state.session.cookiePreferences,
        cookieConsent: state.session.cookieConsent,
      },
    }),
  }).plugin(store)
}
