const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['basket'] = require('../middleware/basket.ts')
middleware['basket'] = middleware['basket'].default || middleware['basket']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['has-basket'] = require('../middleware/has-basket.ts')
middleware['has-basket'] = middleware['has-basket'].default || middleware['has-basket']

middleware['modulesMiddlewareLoader'] = require('../middleware/modulesMiddlewareLoader.js')
middleware['modulesMiddlewareLoader'] = middleware['modulesMiddlewareLoader'].default || middleware['modulesMiddlewareLoader']

middleware['query'] = require('../middleware/query.ts')
middleware['query'] = middleware['query'].default || middleware['query']

export default middleware
